<template>
    <div class="notifications-filters">
        <router-link :to="{ name: 'web-app-inbox-all-notifications' }" class="filter" @click.native="emitSelectedFilter('web-app-inbox-all-notifications')">
            All Notifications
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M7.8457 9.5C7.56956 9.5 7.3457 9.72386 7.3457 10C7.3457 10.2761 7.56956 10.5 7.8457 10.5V9.5ZM17.8457 10.5C18.1218 10.5 18.3457 10.2761 18.3457 10C18.3457 9.72386 18.1218 9.5 17.8457 9.5V10.5ZM7.8457 10.5H12.8457V9.5H7.8457V10.5ZM12.8457 10.5H17.8457V9.5H12.8457V10.5Z" :fill="$route.name == 'web-app-inbox-all-notifications' ? '#05A88F' : '#EBEBF5'" />
                <path
                    d="M1.3457 14.5V4.5C1.3457 2.29086 3.13656 0.5 5.3457 0.5H20.3457C22.5548 0.5 24.3457 2.29086 24.3457 4.5V14.5C24.3457 16.7091 22.5548 18.5 20.3457 18.5H16.3457L12.8457 23.5L9.3457 18.5H5.3457C3.13656 18.5 1.3457 16.7091 1.3457 14.5Z"
                    :stroke="$route.name == 'web-app-inbox-all-notifications' ? '#05A88F' : '#EBEBF5'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </router-link>
        <router-link :to="{ name: 'web-app-inbox-likes-notifications' }" class="filter" @click.native="emitSelectedFilter('web-app-inbox-likes-notifications')">
            Likes
            <svg
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 20.5002C33.2136 6.94411 19.2347 -4.03132 12 3.40269C4.76534 -4.03132 -9.21357 6.94411 12 20.5002Z"
                    :stroke="$route.name == 'web-app-inbox-likes-notifications' ? '#05A88F' : '#EBEBF5'"
                    stroke-opacity="0.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </router-link>
        <router-link :to="{ name: 'web-app-inbox-comments-notifications' }" class="filter" @click.native="emitSelectedFilter('web-app-inbox-comments-notifications')">
            Comments
            <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 10.5332C0 4.91504 5.45707 0.5 12 0.5C18.5429 0.5 24 4.91504 24 10.5332C24 16.1514 18.5429 20.5664 12 20.5664C11.2106 20.5664 10.4385 20.5027 9.69095 20.381C9.13184 20.328 8.81359 20.3759 8.20387 20.5645C8.01248 20.6236 7.77243 20.7499 7.43221 20.9318L7.40839 20.9445C6.68766 21.33 5.66347 21.8777 4.39488 21.8777C4.34429 21.8777 4.20781 21.8697 4.08463 21.7598C3.94306 21.6334 3.92408 21.4706 3.93079 21.3791C3.9368 21.2971 3.96324 21.2369 3.97411 21.2136C3.98767 21.1845 4.00166 21.1621 4.01037 21.1489C4.03963 21.1045 4.07503 21.0664 4.08984 21.0505C4.12768 21.0097 4.18284 20.9551 4.23937 20.8992L4.24638 20.8922C4.45458 20.6861 4.78185 20.3612 5.05719 19.9982C5.34892 19.6137 5.50676 19.282 5.50676 19.0561V18.9737C2.22238 17.2047 0 14.1054 0 10.5332ZM12 1.42308C5.7979 1.42308 0.923077 5.57881 0.923077 10.5332C0.923077 13.7749 2.99286 16.66 6.17788 18.2833L6.42984 18.4118V19.0561C6.42984 19.6107 6.096 20.1562 5.79259 20.5561C5.74544 20.6183 5.69718 20.6792 5.6485 20.7386C6.13967 20.5737 6.58483 20.3381 6.99695 20.1178L7.02536 20.1026C7.32787 19.9407 7.64507 19.7711 7.9311 19.6826C8.63311 19.4655 9.0769 19.3939 9.79494 19.4637L9.81 19.4652L9.82494 19.4676C10.5279 19.5828 11.2552 19.6434 12 19.6434C18.2021 19.6434 23.0769 15.4876 23.0769 10.5332C23.0769 5.57881 18.2021 1.42308 12 1.42308Z"
                    :fill="$route.name == 'web-app-inbox-comments-notifications' ? '#05A88F' : '#EBEBF5'"
                    fill-opacity="0.6"
                />
            </svg>
        </router-link>
        <router-link :to="{ name: 'web-app-inbox-followers-notifications' }" class="filter" @click.native="emitSelectedFilter('web-app-inbox-followers-notifications')">
            Followers
            <svg
                width="20"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5 14C3.067 14 1.5 15.567 1.5 17.5V21C1.5 21.8284 2.17157 22.5 3 22.5H17C17.8284 22.5 18.5 21.8284 18.5 21V17.5C18.5 15.567 16.933 14 15 14H5ZM0.5 17.5C0.5 15.0147 2.51472 13 5 13H15C17.4853 13 19.5 15.0147 19.5 17.5V21C19.5 22.3807 18.3807 23.5 17 23.5H3C1.61929 23.5 0.5 22.3807 0.5 21V17.5Z"
                    :fill="$route.name == 'web-app-inbox-followers-notifications' ? '#05A88F' : '#EBEBF5'"
                    fill-opacity="0.6"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 1C12.7614 1 15 3.23858 15 6C15 8.76142 12.7614 11 10 11C7.23858 11 5 8.76142 5 6C5 3.23858 7.23858 1 10 1ZM10 0C6.68629 0 4 2.68629 4 6C4 9.31371 6.68629 12 10 12C13.3137 12 16 9.31371 16 6C16 2.68629 13.3137 0 10 0Z"
                    :fill="$route.name == 'web-app-inbox-followers-notifications' ? '#05A88F' : '#EBEBF5'"
                    fill-opacity="0.6"
                />
            </svg>
        </router-link>
        <router-link :to="{ name: 'web-app-inbox-mentions-notifications' }" class="filter" @click.native="emitSelectedFilter('web-app-inbox-mentions-notifications')">
            Mentions
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.5148 0.783343C8.71728 -0.336248 3.78134 1.99631 1.60073 6.41315C-0.579867 10.83 0.569586 16.1669 4.37507 19.2945C8.18055 22.4221 13.6391 22.5159 17.5498 19.521C17.8129 19.3196 17.8629 18.943 17.6614 18.6799C17.4599 18.4168 17.0833 18.3668 16.8202 18.5683C13.3522 21.2241 8.51165 21.1409 5.13699 18.3674C1.76233 15.5939 0.74301 10.8612 2.67674 6.94438C4.61046 3.02761 8.98757 0.959076 13.242 1.95194C17.4958 2.94493 20.5049 6.73743 20.5049 11.1049V12.1049C20.5049 13.4303 19.4304 14.5048 18.105 14.5048C16.7796 14.5048 15.7051 13.4303 15.7051 12.1049V7.10503C15.7051 6.77366 15.4365 6.50503 15.1051 6.50503C14.7737 6.50503 14.5051 6.77366 14.5051 7.10503V8.00687C13.6638 7.084 12.4519 6.50488 11.1048 6.50488C8.56432 6.50488 6.50488 8.56432 6.50488 11.1048C6.50488 13.6452 8.56432 15.7046 11.1048 15.7046C12.6728 15.7046 14.0575 14.9201 14.8879 13.7221C15.4803 14.8981 16.6984 15.7048 18.105 15.7048C20.0932 15.7048 21.7049 14.0931 21.7049 12.1049V11.1049C21.7049 6.17974 18.3116 1.9031 13.5148 0.783351L13.5148 0.783343ZM7.70488 11.1048C7.70488 9.22706 9.22706 7.70488 11.1048 7.70488C12.9825 7.70488 14.5046 9.22706 14.5046 11.1048C14.5046 12.9825 12.9825 14.5046 11.1048 14.5046C9.22706 14.5046 7.70488 12.9825 7.70488 11.1048Z"
                    :fill="$route.name == 'web-app-inbox-mentions-notifications' ? '#05A88F' : '#EBEBF5'"
                    fill-opacity="0.6"
                />
            </svg>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "NotificationsFilters",
    props: {
        notificationsLabels: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    methods: {
        emitSelectedFilter(selectedFilter) {
            if (this.notificationsLabels[selectedFilter]) {
                this.$emit("selected-filter", this.notificationsLabels[selectedFilter]);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.notifications-filters {
    display: flex;
    flex-direction: column;

    .filter {
        @extend .body-text;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #EBEBF5;
        text-decoration: none;
        margin-top: 10px;
        margin-bottom: 10px;

        &.router-link-exact-active {
            color: $primary-color;
        }
    }
}
</style>
